@import 'variables.scss';

.full-width {
  width: 100%;
}

.only-card-page {
  display: contents;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white;
  border-radius: 5px;
}

.mat-typography {
  .subtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: initial;
    margin-bottom: 20px;
  }
}

.mat-form-field mat-error {
  display: none;
  &:last-child {
    display: block;
  }
}

.link {
  color: $active-button;
  font-size: 1em;
  
  &:hover {
    cursor: pointer;
    color: $active-button-hover;
  }
}

mat-card {
  display: block;
  box-shadow: none !important;
}

.hello-card {
  color: $secondary-text-color;
}

.title {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  text-align: left;
  font-weight: 500;
  margin-bottom: 10px;
}

.big-title {
  font-size: 1.4286em;
}

.medium-title {
  font-size: 1.2143em;
}

.small-title {
  font-size: 1em;
}

.light-blue-background {
  background-color: $background-color-light-blue;
  color: $primary-text-color;
}

.light-blue-full-width {
  background-color: $background-color-light-blue;
  width: 100%;
}

mat-card.transparent {
  background: transparent;
}

.main-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttons-wrapper {
  gap: 30px;
  display: flex;
  padding-top: 30px;
}

button {
  cursor: pointer;
  height: 48px;
  box-shadow: 0 0 0 0;
  border-radius: 4px;
  font-size: 1em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  padding: 0 16px;
  min-width: 200px;

  &.primary-activate-button {
    border: none;
    color: $color-white !important;
    background-color: $active-button;
    &.disabled {
      background-color: $disabled-button;
      color: $disabled-button-text !important;
      pointer-events: none;
    }
    &:hover {
      background-color: $active-button-hover;
    }
  }

  &.secondary-button {
    color: $primary-text-color !important;
    background-color: $background-color-light-blue;
    border: 1px solid $secondary-button-border;
    &:hover {
      border-color: $secondary-button-hover;
    }
  }
  
  &:focus {
    outline: $active-button auto 1px;
    border: $color-white solid 3px;
  }
  
}

.note {
  color: $secondary-text-color;
  margin-top: 30px;
}

mat-label {
  color: $primary-text-color;
}

::ng-deep .mat-option-text {
  color: $primary-text-color !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: $error-color;
}

.mat-error,
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: $error-color;
}

.success {
  background-color: $success-background;
}

.error {
  background-color: $error-background;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
}
.full-height {
  height: 100%;
  position: relative;
}

.status-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.status-icon > ::ng-deep .mat-icon {
  height: 70px;
  margin-bottom: 40px;
}

.bolded-data {
  word-break: break-all;
  margin-top: 20px;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .light-blue-full-width {
    height: 100%;
  }
  
  mat-card {
    padding: 30px 18px 20px !important;
    border-radius: 0px;
  }

  .bolded-data-big {
    font-size: 1.4286em;
  }

  .bolded-data-medium {
    font-size: 1.2143em;
  }

  .bolded-data-small {
    font-size: 1em;
  }

  .buttons-wrapper {
    gap: 20px;
    flex-direction: column;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .main-div {
    padding: 0 12%;
    font-size: 20px;
  }

  .only-card-page {
    display: unset;
    width: 100%;
    margin-top: 6%;
  }
  
  .light-blue-full-width {
    margin-top: 6%;
    border-radius: 4px;
  }

  mat-card {
    padding: 40px 8.8% 30px 8.8% !important;
    line-height: 28px;

    &.light-blue-background {
      padding: 40px 8.8% !important;
    }

    &.hello-card {
      background: transparent;
    }

    .mat-card-content {
      font-size: unset;
    }
  }
    
  .big-title {
    font-size: 1.8em;
  }

  .medium-title {
    font-size: 1.4em;
  }

  .small-title {
    font-size: 1em;
  }

  #existing-itero-email {
    font-size: 1.4286em;
  }
    
  .status-icon > ::ng-deep .mat-icon {
    height: 93px;
  }
}
