@import "global-styles/variables.scss";
@import "global-styles/material-customizations.scss";

html,
body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: $primary-text-color;
  width: 100vw;
}
