/** Colors **/
$color-white: #FFFFFF;
$background-color-light-blue: #E6F7FF;
$active-button: #009ACE;
$active-button-hover: #008ec2;
$disabled-button: #f5f5f5;
$disabled-button-text: #00000020;
$secondary-button-border: #0000003b;
$secondary-button-hover: #00000057;
$primary-text-color: #000000ED;
$secondary-text-color: #000000A1;
$text-disabled: #8D8D8D;
$success-background: #DCFCE8;
$error-background: #FFF0F3;
$error-color: #d43f58d9;